import React, { useEffect, useState } from "react";
import "./Admin.css";
import AdminSidebar from "./components/AdminSidebar";
import AdminHeader from "./components/AdminHeader";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";

const Reports = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  // const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    document.title = "Activity Log | BarterFunds";
    const token = window.sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_API_URL}/activity-logs`;

    if (!token) {
      navigate("/login");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data.activityLogs)
          const sortedActivities = response.data.activityLogs.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          setActivities(sortedActivities);
          setIsLoading(false);
        } else {
          setActivities([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location.search, navigate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = activities.filter((activity) =>
    activity.browser.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // const totalPages = 10;

  // Calculate the index range of items for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  // Get the data for the current page
  const currentPageData = filteredData.slice(startIndex, endIndex);

  // Pagination handlers
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPM = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    hours = hours.toString().padStart(2, "0");

    return `${hours}:${minutes} ${amPM}`;
  };



  return (
    <div className="page-wrapper default-version">
      <AdminSidebar active={"report"} />
      <AdminHeader />
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="body-wrapper">
            <div className="bodywrapper__inner">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="page-title">Login History</h6>

                <div className="d-flex flex-wrap justify-content-end align-items-center breadcrumb-plugins">
                  <div className="input-group w-auto flex-fill">
                    <input
                      type="search"
                      name="search"
                      className="form-control bg--white text-white"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <button className="btn btn--primary" type="submit">
                      <i className="la la-search" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="card b-radius--10 ">
                    <div className="card-body p-0">
                      <div className="table-responsive--sm table-responsive">
                        <table className="table table--light style--two">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>Login at</th>
                              <th>IP</th>
                              <th>Location</th>
                              <th>Browser | OS</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                          {currentPageData.length === 0 ? (
                            <tr>
                              <td colSpan="8">No data</td>{" "}
                            </tr>
                          ) : (
                            currentPageData.map((activity) => (
                            <tr>
                              <td>
                                <span className="fw-bold">{activity?.user ? `${activity?.user?.firstname} ${activity?.user?.surname}` : 'Unknown user'}</span>
                                <br />

                                {activity?.user ? (
                                  <span className="small">
                                  <a href={`/admin/users/details/${activity?.user?._id}`}>
                                    <span>@</span>{activity?.user?.username}
                                  </a>
                                </span>
                                ) : "No username"}
                                


                              </td>
                              <td>
                                {formatDate(activity?.timestamp)}  <br /> {formatTime(activity?.timestamp)}
                              </td>
                              <td>
                                <span className="fw-bold">
                                  {activity?.ipAddress}
                                </span>
                              </td>
                              <td>
                                {activity?.country ? activity?.country : "Unknown location"} <br />
                                {activity?.latitude !== '0' && activity?.longitude !== '0' ? (
                                  <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${activity?.latitude},${activity?.longitude}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View on map
                                  </a>
                                ) : (
                                  "No location data"
                                )}
                              </td>
                              <td>
                                {activity?.browser}
                              </td>
                              <td>
                                {activity?.status === "success" ? ( 
                                  <span className="badge badge--success">Success</span>
                                ) : (
                                  <span className="badge badge--danger">Failed</span>
                                )}
                              </td>
                            </tr>
                            ))
                          )}
                          </tbody>
                        </table>
                      </div>
                    </div>



                    {currentPageData.length === 0 ? (
                      <p></p>
                    ) : (
                      <div className="card-footer py-4">
                        <nav>
                          <ul className="pagination">
                            {/* Previous Button */}
                            <li
                              className="page-item"
                              onClick={() => goToPage(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              <button
                                className="page-link"
                                disabled={currentPage === 1}
                              >
                                «
                              </button>
                            </li>

                            {/* First Page */}
                            {currentPage > 2 && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => goToPage(1)}
                                >
                                  1
                                </button>
                              </li>
                            )}

                            {/* Ellipsis for pages before current */}
                            {currentPage > 3 && (
                              <li className="page-item disabled">
                                <button className="page-link">...</button>
                              </li>
                            )}

                            {/* Dynamic page links around current page */}
                            {[...Array(totalPages)]
                              .map((_, index) => index + 1)
                              .filter(
                                (page) =>
                                  page === currentPage ||
                                  page === currentPage - 1 ||
                                  page === currentPage + 1
                              )
                              .map((page) => (
                                <li
                                  key={page}
                                  className={`page-item ${
                                    page === currentPage ? "active" : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={() => goToPage(page)}
                                  >
                                    {page}
                                  </button>
                                </li>
                              ))}

                            {/* Ellipsis for pages after current */}
                            {currentPage < totalPages - 2 && (
                              <li className="page-item disabled">
                                <button className="page-link">...</button>
                              </li>
                            )}

                            {/* Last Page */}
                            {currentPage < totalPages - 1 && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => goToPage(totalPages)}
                                >
                                  {totalPages}
                                </button>
                              </li>
                            )}

                            {/* Next Button */}
                            <li
                              className="page-item"
                              onClick={() => goToPage(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              <button
                                className="page-link"
                                disabled={currentPage === totalPages}
                              >
                                »
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Reports;
